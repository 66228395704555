<h1 mat-dialog-title>{{ data.title }}</h1>
<h3>{{ inOnboarding ? 'Olá! Notamos que você ainda não completou essa etapa do Onboarding. Para te levar ao sucesso, precisamos que você conclua esse treinamento.' : data.subtitle }}</h3>
<div *ngIf="!inOnboarding">
  <p>
    {{ data.text }}
  </p>
</div>
<div>
  <button
    *ngIf="!contratar && !inOnboarding"
    class="upbutton"
    mat-raised-button
    color="primary"
    (click)="upgrade()"
  >
    Fazer Upgrade
  </button>
  <button
    *ngIf="contratar"
    class="upbutton"
    mat-raised-button
    color="primary"
    (click)="contratarPlano()"
    [routerLink]="['/contratar-pacote']"
  >
    Contratar Pacote
  </button>
  <button class="upbutton" mat-raised-button color="warn" mat-dialog-close>
    Fechar
  </button>
</div>
